import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import CryptoJS from 'crypto-js';
import axios from "axios";
import "../css/booknow.css";
import { Link } from "react-router-dom";

export const FormComp = () => {
  const [showMessage, setMessage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    ClientName: "",
    ClientEmail: "",
    ClientService: "",
    ClientMessage: "",
  });


  const Api = process.env.REACT_APP_BASE_URL;
  const Key = process.env.REACT_APP_KEY


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function handleSubmit(e) {

    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(formData), Key).toString();
    e.preventDefault();
    try{
    window.scroll(0, 0);
    setMessage(true)
    let res = await axios.post(Api, {encryptedData});
    if(res.status===200){
      setMessage(false)
      navigate("/thank-you")
    }
  }
    catch(err){
      setMessage(false)
      setTimeout(function() {
        alert("something went wrong, please reach out to us at +919906007524")

      }, 100);
    }

    setFormData({
      ClientName: "",
      ClientEmail: "",
      ClientService: "",
      ClientMessage: "",
    });
  }

  return (
    <div className="formBoxConatiner">
      <form onSubmit={handleSubmit} name="contact">
        <input type="hidden" name="form-name" value="contact" />
        <div className="AboutUsFormInputBox">
          <div className="labelAboutUs"> Enter Your Name:</div>
          <input
            type="text"
            name="ClientName"
            value={formData.ClientName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="AboutUsFormInputBox">
          <div className="labelAboutUs"> Enter Your Email:</div>
          <input
            type="email"
            name="ClientEmail"
            value={formData.ClientEmail}
            onChange={handleChange}
            required
          />
        </div>
        <div className="AboutUsFormInputBox">
          <div className="labelAboutUs"> Services Needed:</div>
          <input
            type="text"
            name="ClientService"
            value={formData.ClientService}
            onChange={handleChange}
            placeholder="ex. web development, maintenance"
            required
          />
        </div>
        <div className="AboutUsFormInputBox">
          <div className="labelAboutUs"> Leave Us a Message:</div>
          <textarea
            name="ClientMessage"
            value={formData.ClientMessage}
            onChange={handleChange}
            placeholder="optional"
          />
        </div>
        <div className="termsText">
          On clicking on "Send" you accept our{" "}
          <Link target="blank" to={"/terms-conditions"}>
            Terms and Conditions
          </Link>{" "}
          and confirm that you have read our{" "}
          <Link target="blank" to={"/privacy-policy"}>
            Privacy Policy
          </Link>
        </div>
        <button className="about-btn" type="submit">
          Send
        </button>
      </form>
      
      {showMessage ? <Thankyou/> : null}
    </div>
  );
};

const Thankyou = () => {

  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);
  return (
    <ThreeCircles
  visible={true}
  height="100"
  width="100"
  color="#4fa94d"
  ariaLabel="three-circles-loading"
  wrapperStyle={{}}
  wrapperClass=""/>
  );
};
