import React from 'react';
import "../css/footer.css"
import { Link } from 'react-router-dom';
import FooterLogo from "../assets/rnextsvg.svg"
const Footer = () => {


  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2>About Us</h2>
          <p>We are a leading web development company dedicated to delivering high-quality, innovative solutions to our clients.</p>
          <div className="contact">
            <span><i className="fas fa-phone"></i> (+91) 9906007524</span>
            <span><i className="fas fa-envelope"></i> info@rdnext.in</span>
          </div>
        </div>
        <div className="footer-section links">
          <h2>Quick Links</h2>
          <ul>
            <li><Link to={"/our-services"}>Services</Link></li>
            <li><Link to={"/about-us"}>About us</Link></li>
            <li><Link to={"/book-now"}>Book Now</Link></li>
            <li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
            <li><Link to={"/terms-conditions"}>Terms and Conditions</Link></li>

          </ul>
        </div>
        <div className="footer-section contact-form">
          <h2>Address</h2>
          <p>India</p>
          <p>Jammu and Kashmir</p>
          <p>Umarabad Srinagar - 190012</p>

          <p><a href="/sitemap.xml" rel="noopener noreferrer">Sitemap</a></p>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; {new Date().getFullYear()} <img className='footerLogo' src={FooterLogo} alt='logo'/>Rdnext Web Technologies LLP website. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
