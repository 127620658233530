import React, { useEffect, useState } from "react";
import Logo from "../assets/rnext.png";
import MenuIcon from "../assets/menu2.png";
import LogoMob from "../assets/rnext.png";
import "../css/header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [showMenu, setMenu] = useState(false);
  useEffect(() => {
    // Cleanup function to remove noScroll class when component is unmounted
    return () => {
      document.body.classList.remove("noScroll");
    };
  }, []);
  function handleMenu() {
    setMenu(!showMenu);
    if (!showMenu) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }
  }

  const UnderLined = ({ location, tabName }) => {
    if (window.location.pathname === location)
      return (
        <div className="tab tabUnderlined">
          <Link to={location}>{tabName}</Link>
        </div>
      );
    else
      return (
        <div className="tab">
          <Link to={location}>{tabName}</Link>
        </div>
      );
  };
  const MenuComp = () => (
    <>
      <div className="optionsMenuBox">
      <button onClick={handleMenu} class="close-btn">&times;</button>
        <div className="pMobile">
          <UnderLined tabName={"Home"} location={"/"} />
          <UnderLined tabName={"Services"} location={"/our-services"} />
          <UnderLined tabName={"About Us"} location={"/about-us"} />
          <UnderLined tabName={"Book Now"} location={"/book-now"} />
          <UnderLined tabName={"Careers"} location={"/careers"} />

        </div>
      </div>
    </>
  );
  return (
    <>
      <div className="headerContainer">
        <div className="headerOptions">
          <div>
          <div className="logoContainer">
            <Link to={"/"}>
              <img className="logoImg" src={Logo} alt="logo" />{" "}
            </Link>
          </div>
          </div>
         <div className="tab-options">
          <UnderLined tabName={"Home"} location={"/"} />
          <UnderLined tabName={"Services"} location={"/our-services"} />
          <UnderLined tabName={"About Us"} location={"/about-us"} />
          <UnderLined tabName={"Book Now"} location={"/book-now"} />
          <UnderLined tabName={"Careers"} location={"/careers"} />
        </div>
        </div>
      </div>
      <div className="headerMobile">
        <div className="headerMobileOptions">
          <div className="logoContainer">
            <Link to={"/"}>
            <img className="logoImg" src={LogoMob} alt="logo" />
            </Link>
          </div>
          <div className="menuIconContainer">
            <img
              className="menuIconImg"
              src={MenuIcon}
              onClick={handleMenu}
              alt="menuIcon"
            />
          </div>
        </div>
        {showMenu ? <MenuComp /> : null}
      </div>
    </>
  );
};

export default Header;
