import React, { useEffect } from 'react';
import '../css/services.css'; // Import CSS for styling
import AOS from 'aos';
import 'aos/dist/aos.css';
// import { PaymentButton } from './homepageComp';

export const OurServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
    AOS.init({
      duration: 2000, // Animation duration
      once: false, // Whether animation should happen only once
    });
  }, []);

  return (
    <section id="our-services">
      <div className="container">
        <h2 data-aos="zoom-in">Our Services</h2>
        <div className="service" data-aos="fade-right">
          <h3>Website Development</h3>
          <p>
            We offer website development services for various needs, including basic websites, e-commerce platforms, electronic payment gateways (EPG), and auditing systems.
          </p>
        </div>
        <div className="service" data-aos="fade-left">
          <h3>Hosting</h3>
          <p>
            We provide hosting solutions for your website, including setup, maintenance, and 1 year of three-tier IT support.
          </p>
        </div>
        <div className="service" data-aos="fade-up">
          <h3>Customized Domain Name</h3>
          <p>
            We offer assistance in selecting and registering a custom domain name that suits your brand, with associated costs.
          </p>
        </div>
        <div className="service" data-aos="fade-down">
          <h3>Email Hosting via Microsoft</h3>
          <p>
            We provide email hosting services using Microsoft's platform, ensuring professional communication channels for your business, with associated costs.
          </p>
        </div>
        <div className="clients" data-aos="flip-left">
          <h3>Our Clients</h3>
          <ul>
            <li>Sukhnag Infra</li>
          </ul>
        </div>
      </div>
    </section>
  );

}