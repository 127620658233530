import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./chatbot.css";

const Chatbot = () => {
  const [messages, setMessages] = useState([{ sender: "bot", text: "Hi!" }]);
  const [userInput, setUserInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [istyping, setTyping]= useState(true)
  const messagesEndRef = useRef(null);

  const sendMessage = async () => {
    setTyping(false)

    const userMessage = userInput.trim();
    if (userMessage) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "user", text: userMessage },
      ]);
      setUserInput("");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}chat`,
          { message: userMessage }
        );
        if(response.status === 200){
          setTyping(true)
        }
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: response.data.response },
        ]);
      } catch (error) {
        console.error("Error communicating with the server:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: "I am not sure how to respond to that." },
        ]);
      }
    }
  };

  function captureUrl(str, e) {
    // Find the index of ".in" in the string
    const startIndex = str.indexOf(".in");

    if (startIndex !== -1) {
      // Start from the end of ".in"
      let endIndex = startIndex + 3;

      // Check if there are any characters after ".in"
      if (endIndex < str.length) {
        // Return the substring after ".in"
        return str.substring(endIndex).trim();
      } else {
        // Return an empty string if there's nothing after ".in"
        return "#";
      }
    }

    // Prevent default event behavior and return fallback value if e is provided
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    return "#";
  }

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className={`chatbot-container ${isOpen ? "open" : ""}`}>
      <div className="chatbot-header" onClick={() => setIsOpen(!isOpen)}>
        <i className={`fas fa-${isOpen ? "times" : "comment"}`}></i>
      </div>
      {isOpen && (
        <div className="chatbot-content">
          Hello, Welcome to RDNEXT
          <div className="chatbot-messages">
            {messages.map((msg, index) => (
              <div key={index} className={`chatbot-message ${msg.sender}`}>
                <a href={captureUrl(msg.text)}>{msg.text}</a>
              </div>
            ))}
            <div ref={messagesEndRef} />
            {istyping ? null : <Typing />}
          </div>
          <div className="chatbot-input">
            <input
              type="text"
              autoFocus
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && sendMessage()}
            />
            <button onClick={sendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;

export const Typing = () => {
  return (
    <div>
      <div className="chatbot-messages rmv-padding"><i>typing...</i></div>
    </div>
  );
};
