import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/footer";
import { AboutUs } from "../components/aboutusComp";

export const AboutUsPage = () => {
  return (
    <div>
      <Helmet>
        <title>About Us | Rdnext Web Technologies LLP</title>

        <meta
          name="description"
          content="Discover more about rdnext, a leading web development company dedicated to delivering high-quality, innovative solutions to our clients. Learn about our team of dedicated professionals and our commitment to excellence."
        />
      </Helmet>

      <Header />
      <AboutUs />
      <Footer />
    </div>
  );
};
