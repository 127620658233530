import React from "react";
import { Helmet } from "react-helmet";
import { ChooseUs, HeroSection, Offerings } from "../components/homepageComp";
import Header from "../components/Header";
import Footer from "../components/footer";

export const Homepage = () => {
  return (
    <div>
       <Helmet>
        <title>Best Web Development Services | Rdnext Web Technologies LLP</title>
        <meta name="description" content="Discover the best web development services offered by rdnext. We specialize in custom website design, responsive front-end development, robust back-end solutions, and more. Get in touch today!" />
      </Helmet>
      <Header />
      <HeroSection />
      <ChooseUs />
      <Offerings />
      {/* <PaymentButton/> */}
      <Footer />
    </div>
  );
};
