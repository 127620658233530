// src/components/Careers.js
import React, { useState, useEffect } from "react";
import ApplyModal from "./Modal";
import "../css/careers.css";
import Footer from "../components/footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet";

const Careers = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const positions = [
    {
      id: 1,
      title: "Frontend Developer",
      description:
        "Expert in React and CSS. You will be responsible for building responsive and interactive user interfaces.",
    },
    {
      id: 2,
      title: "Backend Developer",
      description:
        "Expert in Node.js and MongoDB. You will be responsible for building robust and scalable backend services.",
    },
    // Add more positions as needed
  ];

  const openModal = (position) => {
    setSelectedPosition(position);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Careers | Rdnext Web Technologies LLP</title>
        <meta property="og:title" content= "Careers | Rdnext Web Technologies LLP" />
        <meta
          name="description"
          content="Explore exciting career opportunities at Rdnext Web Technologies LLP. Join us to deliver the best in your field. Discover fulfilling roles and grow your career with a dynamic and innovative team."
        />
                <meta property="og:description" content="Explore exciting career opportunities at Rdnext Web Technologies LLP. Join us to deliver the best in your field. Discover fulfilling roles and grow your career with a dynamic and innovative team." />

      </Helmet>
      <Header />
      <section id="careers">
        <div className="container">
          <h2>Careers</h2>
          <p>Join our team and help us build amazing products.</p>
          <div className="positions">
            {positions.map((position) => (
              <div key={position.id} className="position">
                <h3>{position.title}</h3>
                <p>{position.description}</p>
                <button onClick={() => openModal(position)}>Apply Now</button>
              </div>
            ))}
          </div>
        </div>
        {selectedPosition && (
          <ApplyModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            position={selectedPosition}
          />
        )}
      </section>
      <Footer />
    </>
  );
};

export default Careers;
