import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/footer";
import { OurServices } from "../components/servicesComponent";

export const Services = () => {
  return (
    <div>
      <Helmet>
  <title>Services | Rdnext Web Technologies LLP</title>
  <meta name="description" content="Explore the comprehensive range of web development services offered by Rdnext Web Technologies LLP. From custom website development and web application solutions to responsive web design and e-commerce platforms, we have the expertise to meet your digital needs." />
  {/* Add more meta tags as needed */}
</Helmet>

      <Header />
      <OurServices/>
      <Footer />
    </div>
  );
};
