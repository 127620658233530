import "../css/aboutus.css";
// import DirectorImg from "../assets/director.png";
import { useEffect } from "react";
// import Cert1Img from "../assets/crio certificate.jpeg";
// import CertImg2 from "../assets/crio-fullstack.jpeg"
export const AboutUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
    
  },[]);

  return (
    <section id="about-us">
      <div className="container">
        <h2>About Us</h2>
        <p>
          Welcome to our company! We are a team of passionate individuals
          dedicated to delivering high-quality web development solutions to our
          clients. Our mission is to create innovative and user-friendly
          websites that help businesses thrive in the digital world.
        </p>
        {/* <h3>Director & Founder</h3>
        <div className="director">
          <img src={DirectorImg} alt="DirectorPhoto" />
          <div className="director-info">
            <h4>Rawahul Islam</h4>
            <p>
              Rawahul Islam, an electrical engineer by education, discovered his
              passion for web development and decided to pursue it further. With
              a strong background in engineering and a newfound love for
              programming, Rawahul founded our company with the vision of
              providing top-notch web development services to clients worldwide.
            </p>
          </div>
        </div> */}

        {/* <div
          className="badge-base LI-profile-badge"
          data-locale="en_US"
          data-size="large"
          data-theme="light"
          data-type="HORIZONTAL"
          data-vanity="rawahulislam"
          data-version="v1"
        >
          <a
            className="badge-base__link LI-simple-link"
            href="https://in.linkedin.com/in/rawahulislam?trk=profile-badge"
          >
            "want no text here"
          </a>
        </div> */}

        {/* <h3>Certifications</h3> */}
        {/* <div className="certifications">
          <div className="cert-item">
           <a  target="blank" href="https://www.crio.do/learn/certificate/crio-8715096926-abd/TRACK_FELLOWSHIP_FULL_STACK_V3/"><img className="img1" src={Cert1Img} alt="Certificate 1" /></a>
          </div>
          <div className="cert-item">
          <a  target="blank" href="https://www.crio.do/learn/certificate/crio-8715096926-abd/TRACK_FELLOWSHIP_FULL_STACK_V3/"><img className="img1" src={CertImg2} alt="Certificate 2" /></a>
          </div>
        </div> */}
      </div>
    </section>
  );
};
