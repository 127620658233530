import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../css/homepage.css";

// import HeroVid from "../assets/herovid.mp4"

// import HeroImageHome from "../assets/hero.jpg";
// import { ButtonComp } from "../components/button";
import { Link } from "react-router-dom";

export const HeroSection = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  // const navigate = useNavigate()
  return (
    <div className="hero-banner">
      <div className="content">
        <h1>Transform Your Business</h1>
        <p>Discover the power of innovation and take your business to new heights with our cutting-edge solutions.</p>
        <Link to={"/book-now"}><><button>Book Now</button></></Link>
      </div>
      <div className="scroll-down">
        <span></span>
      </div>
    </div>
  );
};

export const Offerings = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="offering-container">
      <div className="offering-heading" data-aos="fade-down">What We Offer</div>
      <div className="offering-box-container">
        {offerings.map((offering, index) => (
          <div className="offering-item" key={index} data-aos="fade-right" data-aos-delay={index * 100}>
            <div className="offering-content">
              <div className="offering-title">{offering.heading}</div>
              <div className="offering-description">{offering.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const offerings = [
  {
    heading: 'Custom Website Development',
    description: 'Tailored solutions to meet the unique needs and goals of each client. Development of fully customized websites from scratch, ensuring a unique online presence.',
  },
  {
    heading: 'Web Application Development',
    description: 'Building dynamic web applications with interactive features and functionalities, tailored to specific business requirements. Expertise in technologies such as JavaScript frameworks, server-side scripting, and databases (MySQL, MongoDB).',
  },
  {
    heading: 'Responsive Web Design',
    description: 'Creation of websites that adapt seamlessly to different devices and screen sizes, providing a consistent user experience. Mobile-first approach to design, ensuring optimal performance on smartphones and tablets.',
  },
  {
    heading: 'E-Commerce Solutions',
    description: 'Development of robust e-commerce platforms for online stores, including features like product catalog, shopping cart, secure payment gateways, and order management.',
  },
  {
    heading: 'Content Management Systems (CMS)',
    description: 'Integration of user-friendly CMS platforms like WordPress, Joomla, or Drupal, empowering clients to manage and update their website content easily.',
  },
  {
    heading: 'Website Maintenance and Support',
    description: 'Ongoing maintenance services to ensure websites remain secure, up-to-date, and functioning smoothly. Timely updates, security patches, backups, and troubleshooting to address any issues and minimize downtime.',
  },
  {
    heading: 'Consultation and Strategy',
    description: 'Consultative approach to understand client objectives, target audience, and business goals. Strategic planning and roadmap development to align web development initiatives with broader business objectives.',
  },
  {
    heading: 'Integration and Third-party Services',
    description: 'Integration of third-party services, APIs, and extensions to extend website functionality and enhance user experience. Integration with social media platforms, payment gateways, CRM systems, and other business tools.',
  },
];

export const ChooseUs = () => {
  return (
    <section className="choose-us" data-aos="fade-up">
      <div className="choose-us-heading">Why Choose Us?</div>
      <div className="choose-us-points">
        {points.map((point, index) => (
          <div className="choose-us-point" key={index} data-aos="zoom-in" data-aos-delay={index * 100}>
            <h3>{point.heading}</h3>
            <p>{point.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

const points = [
  {
    heading: 'Expertise and Experience',
    description: 'With years of experience, our team has the expertise to deliver top-notch web development solutions.',
  },
  {
    heading: 'Customized Solutions',
    description: 'We specialize in providing customized solutions tailored to meet your specific business needs and objectives.',
  },
  {
    heading: 'Client-Centric Approach',
    description: 'Our client-centric approach ensures that your goals and requirements are always our top priority.',
  },
  {
    heading: 'Quality and Innovation',
    description: 'We are committed to delivering high-quality, innovative solutions that set your business apart.',
  },
  {
    heading: 'Transparent Processes',
    description: 'Our transparent processes and pricing ensure clear communication and accountability throughout the project.',
  },
];

export const PaymentButton = () => {
  return (
    <div className="payment-button-container" data-aos="fade-up">
      <div className="payment-prompt-message">
        <p>Dear Supporter,</p>
        <p>Your contribution is greatly appreciated. Would you like to make a payment or donate?</p>
        <p>Please click the button below to proceed:</p>
        <a href="https://rzp.io/l/bzmausZ5">
          <button className="payment-action-button">Proceed</button>
        </a>
      </div>
    </div>
  );
};