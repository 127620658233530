import { Services } from "./pages/services";
import { Homepage } from "./pages/homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AboutUsPage } from "./pages/aboutus";
import { BookNowPage } from "./pages/booknow";
import PrivacyPolicy from "./pages/privacyPolicy";
import Terms from "./pages/terms";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Logo from "./assets/logo512.png";
import "./App.css";
import Thankyoupage from "./pages/thankyoupage";
import Careers from "./pages/careers";
import Chatbot from "./chatbot/chatbot";
import '@fortawesome/fontawesome-free/css/all.min.css';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route exact path="/our-services" element={<Services />} />
          <Route exact path="/about-us" element={<AboutUsPage />} />
          <Route exact path="/book-now" element={<BookNowPage />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-conditions" element={<Terms />} />
          <Route exact path="/thank-you" element={<Thankyoupage />} />
          <Route exact path="/careers" element={<Careers />} />


        </Routes>
      </BrowserRouter>

      <FloatingWhatsApp
        phoneNumber="+919906007524"
        allowEsc="true"
        accountName="RDNEXT Team"
        darkMode="true"
        avatar={Logo}
      />
      <Chatbot/>
    </div>
  );
}

export default App;
