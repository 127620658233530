import React from 'react';
import { useEffect } from 'react';
import "../css/thankyou.css";
import GIF from "../assets/confeti_square.gif"
import IMG from "../assets/check-confetti.svg"
import { useNavigate } from 'react-router';


const Thankyoupage = () => {
  const navigate = useNavigate()

  useEffect(() => {

    const originalBackgroundColor = document.body.style.backgroundColor;
    
    const timeoutId = setTimeout(() => {
      navigate("/");
    }, 5000);
    document.body.style.backgroundColor = "white";
    return () => {clearTimeout(timeoutId);
      document.body.style.backgroundColor = originalBackgroundColor;

    }
    //eslint-disable-next-line
  }, []);
  
  return (
    <>
    <div className='ThankContainer'>
      <div style={{ textAlign: 'center', fontSize: '18px', lineHeight: '24px', margin: '0 auto', maxWidth: '480px', padding:'5%' }}>
        <div style={{ position: 'relative', display: 'inline-block', marginBottom: '32px' }}>
          <div style={{ position: 'relative', zIndex: '1', background: `url(${IMG}) center center no-repeat`, backgroundSize: 'cover', width: '152px', height: '152px' }}></div>
          <div style={{ position: 'absolute', top: '0', left: '0', background: `url(${GIF}) center center no-repeat`, backgroundSize: '97% 97%', width: '152px', height: '152px' }}></div>
        </div>
        <p><strong>Fantastic! We have recieved your Query.</strong></p>
        <p>Our team is now working hard at work to get you the best.</p>
        <p>Hope you will like our services.</p>
      </div>
    </div>
    </>
  );
};

export default Thankyoupage;
